import { lazy } from "react";
import { AuthAuto } from "../routes/AuthAuto";

const { Faq } = lazy(() => import("../routes/Faq"));
const { NewsList } = lazy(() => import("../routes/NewsList"));
const { Social } = lazy(() => import("../routes/Social"));
const Lightboxes = lazy(() => import("../routes/Lightboxes"));
const Stickers = lazy(() => import("../routes/Stickers"));
const BusMonitors = lazy(() => import("../routes/BusMonitors"));
const News = lazy(() => import("../routes/News"));
const Beauty = lazy(() => import("../routes/Beauty"));
const Ooh = lazy(() => import("../routes/Ooh"));
const VideoTaxi = lazy(() => import("routes/VideoTaxi"));
const StandAlone = lazy(() => import("routes/StandAlone"));
const Mail = lazy(() => import("routes/Mail"));
const Gmg = lazy(() => import("routes/Gmg"));
const BusBranding = lazy(() => import("routes/BusBranding"));
const MetroNovosibirsk = lazy(() => import("routes/MetroNovosibirsk"));
const Cdek = lazy(() => import("routes/Cdek"));
const OutdoorNovosib = lazy(() => import("routes/OutdoorNovosib"));
const NovosibirskBus = lazy(() => import("routes/NovosibirskBus"));
const Benches = lazy(() => import("routes/Benches"));
const Stand = lazy(() => import("routes/Stand"));
const CardTemplate = lazy(() => import("routes/CardTemplate"));
const CardCityFormat = lazy(() => import("routes/CardCityFormat"));
const CardStickersSpb = lazy(() => import("routes/CardStickersSpb"));
const CardCityFormatStandalone = lazy(() =>
  import("routes/CardCityFormatStandalone")
);
const CommercialNavigationMetro = lazy(() =>
  import("routes/CommercialNavigationMetro")
);
const CityFormat = lazy(() => import("routes/CityFormat"));
const Activities = lazy(() => import("routes/Activities"));
const CardCristalLight = lazy(() => import("routes/CardCristalLight"));
const CardCityFormatGroupSpace = lazy(() =>
  import("routes/CardCityFormatGroupSpace")
);
const CardCityFormatGroup = lazy(() => import("routes/CardCityFormatGroup"));
const CardGroundTransportation = lazy(() =>
  import("routes/CardGroundTransportation")
);
const CardMediaFacade = lazy(() => import("routes/CardMediaFacade"));

export const routes = [
  {
    path: "/activities",
    component: Activities
  },
  {
    path: "/city-format",
    component: CityFormat
  },
  {
    path: "/card-template/:inventory",
    component: CardTemplate
  },
  {
    path: "/card-cityformat/:id",
    component: CardCityFormat
  },
  {
    path: "/card-stickers-spb/:lineId",
    component: CardStickersSpb
  },
  {
    path: "/card-stickers-spb/:lineId/:formatId",
    component: CardStickersSpb
  },
  {
    path: "/card-cityformat-standalone/:id",
    component: CardCityFormatStandalone
  },
  {
    path: "/card-cityformat-group/:groupId/:spaceId",
    component: CardCityFormatGroupSpace
  },
  {
    path: "/card-cityformat-group/:groupId",
    component: CardCityFormatGroup
  },
  {
    path: "/card-ground-transportation/:routeId",
    component: CardGroundTransportation
  },
  {
    path: "/card-media-facade/:facadeId",
    component: CardMediaFacade
  },
  {
    path: "/card-cristallight/:id",
    component: CardCristalLight
  },
  {
    path: "/commercial-navigation-metro",
    component: CommercialNavigationMetro
  },
  {
    path: "/advertising-stand",
    component: Stand
  },
  {
    path: "/outdoor-novosibirsk",
    component: OutdoorNovosib
  },
  {
    path: "/cdek",
    component: Cdek
  },
  {
    path: "/pews",
    component: Benches
  },
  {
    path: "/bus-branding-novosibirsk",
    component: NovosibirskBus
  },
  {
    path: "/metro-novosibirsk",
    component: MetroNovosibirsk
  },
  {
    path: "/bus-branding",
    component: BusBranding
  },
  {
    path: "/mediafacade",
    component: Gmg
  },
  {
    path: "/mail-russia",
    component: Mail
  },
  {
    path: "/standalone",
    component: StandAlone
  },
  // {
  //   path: "/transport/video-taxi",
  //   component: VideoTaxi
  // },
  {
    path: "/ooh",
    component: Ooh
  },
  {
    path: "/beauty",
    component: Beauty
  },
  {
    path: "/auth-auto",
    component: AuthAuto
  },
  {
    path: "/",
    component: Lightboxes
  },
  // {
  //   path: "/transport/bus-monitors",
  //   component: BusMonitors
  // },
  {
    path: "/faq",
    component: Faq
  },
  {
    path: "/news/:id",
    component: News
  },
  {
    path: "/news",
    component: NewsList
  },
  {
    path: "/social",
    component: Social
  }
  // {
  //   path: "/",
  //   component: Stickers
  // }
];
